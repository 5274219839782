<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <h3 class="mt-3 ml-6">REPORTES</h3>
          <v-spacer> </v-spacer>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" class="pt-0">
            <v-list>

              <v-list-item>
                <v-list-item-avatar>
                  <!-- <v-btn color="success" fab @click="viewDialogDownload(1)"><i class="fas fa-file-excel" style="font-size:18px"></i></v-btn> -->
                  <v-btn x-small @click="viewDialogDownload(1)">
                    <v-icon>mdi-cloud-download</v-icon></v-btn
                  >
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b
                      ><a style="color: black">Reporte - Kardex maduración </a></b
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

             

            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="dialogDownload" v-if="dialogDownload" width="600">
      <v-card>
        <v-container>
          <v-row>
            <s-toolbar
              label="Descargar Reporte"
              excel
              @excel="ReportDownloadExcel()"
              pdf
              @pdf="ReportDownloadPDF"
            >
            </s-toolbar>
          </v-row>
          <v-row>
            <v-col cols="6">
              <s-date label="Fecha Inicio" v-model="filter.DateBegin"></s-date>
            </v-col>
            <v-col cols="6">
              <s-date label="Fecha Fin" v-model="filter.DateEnd"></s-date>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import _sReportMadurationService from "../../../services/FrozenProduction/ReportMaduration/ReportMadurationService.js";

// import EntryMaterial from "../../../services/FreshProduction/EntryMaterialRawService";
// import LotRejectedByMatureService from "../../../services/FreshProduction/LotRejectedByMatureService";
// import _sReportService from "../../../services/FreshProduction/Report/ReceptionFreshReportService.js";
/* import sTurnInProcess from "@/components/FreshProduction/Turn/sTurnInProcess.vue" */

export default {
  components: {},
  data() {
    return {
      filter: {},
      dialogDownload: false,
      xFlag: 0,
    };
  },

  methods: {

    viewDialogDownload(xFlag) {
      this.xFlag = xFlag;
      this.dialogDownload = true;
    },

    ReportDownloadExcel() {
      if (this.xFlag == 1) {
        //INGRESO DE MATERIA PRIMA
        this.prfCardexMadurationReportExcel();
      }
    },

    ReportDownloadPDF() {
      if (this.xFlag == 1) {
        //INGRESO DE MATERIA PRIMA
        this.prfCardexMadurationReportPDF();
      }
    },


    // ==========================================================================

    prfCardexMadurationReportExcel() {
      _sReportMadurationService.kardexMadurationExcel(this.filter,this.$fun.getUserID())
      .then((r)=>{
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "KardexMaduración");
      });
    },

    prfCardexMadurationReportPDF(){
      _sReportMadurationService.kardexMadurationPDF(this.filter, this.$fun.getUserID())
      .then((r)=>{
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          "Kardex Madurez"
        );
      });
    },

    reportBRIX() {
      _sReportService.BRIX(this.filter).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "BRIX");
      });
    },

    reportBrixPDF() {
      _sReportService.brixRPdf(this.filter, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.PDF,
          "Control indicencia madurez de la fruta"
        );
      });
    },
  },
};
</script>